@import '../../../styles/variables.scss';

.container {
  .anim {
    animation: anim 0.2s ;
    @include animation-mixin-x(anim , -100px , 0);
  }
  h1 {
    font-family: "Raleway-Bold";
    font-size: 44px;
    margin-top: 18%;
    margin-bottom: 20px;
    max-width: 200px;
    @media only screen and (min-width: $sm-screen) {
      max-width: 100%;
      margin-top: 0%;
    }
  }
  p {
    max-width: 95%;
  }
  button {
    margin-top: 30px;
  }
  .illu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 250px;
    @media only screen and (min-width: $sm-screen) {
      width: 55%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &.anim {
      animation: anim-left 0.2s ;
      @include animation-mixin-x(anim-left , 100px , 0);
    }
    img {
      width: auto;
      max-width: 650px;
      max-height: 100%;
      @include transition;
      &.puzzle {
        transform: scale(1.1)
      }
    }
  }
}