@import './variables.scss';

body, html {
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
  color: $dark;
  font-family: "Raleway-Regular", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color:  $bg-color;
}

.page-container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  .page-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 60px;
    left: 0;
    width: 100%;
    overflow: auto;
    height: calc(100vh - 60px);
    @media only screen and (min-width: $sm-screen) {
      top: 70px;
      height: calc(100vh - 70px);
    }
    .content {
      width: 100%;
      height: 100%;
      padding: 0px 20px;
      max-width: 1200px;
      @media only screen and (min-width: $sm-screen) {
        padding: 30px ;
      }
    }
  }
}

.uppercase {
  text-transform: uppercase;
}

.bold {
  font-family: "Raleway-Bold";
}

.regular {
  font-family: "Raleway-Regular";
}

label {
  font-family: "Raleway-Bold";
  font-weight: bold;
  font-size: 14px;
}

button {
  @include btn;
}

.text-primary {
  color: $primary;
}

.text-center {
  text-align: center;
}

.desktop {
  display: none;
  @media only screen and (min-width: $sm-screen) {
    display: block;
  }
}

.mobile{
  display: block;
  @media only screen and (min-width: $sm-screen) {
    display: none;
  }
}

.anim-bottom {
  animation: anim 0.5s ;
  @include animation-mixin-y(anim , 100px , 0);
}


@mixin col-1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 50%;
  @media only screen and (min-width: $sm-screen) {
    height: 100%;
  }
}

.row {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  @media only screen and (min-width: $sm-screen) {
    flex-direction: row;
    justify-content: space-between;
  }
  .col-1 {
    @include col-1();
  }
  .col-2 {
    @include col-1();
    @media only screen and (min-width: $sm-screen) {
      width: 45%;
      justify-content: center;
    }
    &.illu {
      display: none;
      width: 50%;
      height: 100%;
      &.anim {
        animation: anim-left 0.2s ;
        @include animation-mixin-x(anim-left , 100px , 0);
      }
      img {
        width: 100%;
        max-width: 650px;
        max-height: 100%;
        @include transition;
      }
      @media only screen and (min-width: $sm-screen) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .col-3 {
    @include col-1();
    @media only screen and (min-width: $sm-screen) {
      width: calc(33.33% - 10px);
    }
  }
  .col-4 {
    @include col-1();
    @media only screen and (min-width: $sm-screen) {
      width: calc(25% - 10px);
    }
  }
}


.bg-grey {
  background-color: #F2F4FB;
}

.radius {
  border-radius: 5px;
}
 
