@import '../../../styles/variables.scss';

.container {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 5%;
  @media only screen and (min-width: $sm-screen) {
    min-height: 90%;
  }
  @media only screen and (min-width: $md-screen) {
    padding-top: 5%;
    min-height: 80%;
  }
  @media only screen and (min-width: $lg-screen) {
    min-height: 70%;
  }
  &.anim {
    animation: anim 0.2s ;
    @include animation-mixin-x(anim , -100px , 0);
  }
  h2 {
    color: $primary;
    font-family: "Raleway-Bold";
    margin-bottom: 20px
  }
  p {
    margin: 20px 0px
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding: 20px 0px;
    button {
      font-size: 12px;
      min-width: 120px;
      @media only screen and (min-width: $xxs-screen) {
        font-size: 14px;
        min-width: 140px;
      }
      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}