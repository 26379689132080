@import '../../../styles/variables.scss';

.container {
  width: 100%;
  height: 60px;
  padding: 10px 20px;
  // position: fixed;
  top: 0px;
  @media only screen and (min-width: $sm-screen) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    height: 80px;
  }
  img {
    display: none;
    @media only screen and (min-width: $sm-screen) {
      display: block;
      height: 50px;
    }
  }
  a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    color: $dark;
    @media only screen and (min-width: $sm-screen) {
      position: absolute;
      left: 20px;
    }
    img {
      height: 40px;
      margin-right: 10px;
      display: block;
      @media only screen and (min-width: $sm-screen) {
        display: none;
      }
    }
    svg {
      fill: $primary; 
    }
    p {
      margin-left: 10px;
      text-transform: uppercase;
      font-family: "Raleway-Bold";
    }
  }
}