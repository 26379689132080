@import '../../../styles/variables.scss';

.container {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 5%;
  @media only screen and (min-width: $sm-screen) {
    min-height: 90%;
  }
  @media only screen and (min-width: $md-screen) {
    padding-top: 0%;
  }
  @media only screen and (min-width: $lg-screen) {
    min-height: 80%;
  }
  
  .anim {
    animation: anim 0.2s ;
    @include animation-mixin-x(anim , -100px , 0);
  }

  .message {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media only screen and (min-width: $sm-screen) {
      height: 100%;
    }
    img {
      max-width: calc(100% - 40px);
      margin: 20px;
    }
    h1 {
      font-family: "Raleway-Bold";
      font-family: "Raleway-Bold";
      font-size: 22px;
      margin-bottom: 20px;
      width: 98%;
      @media only screen and (min-width: $sm-screen) {
        max-width: 100%;
        font-size: 40px;
      }
    }
    p {
      margin-bottom: 30px;
    }
    button {
      margin: 30px 0px;
    }
    .connect {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      label {
        font-size: 16px;
      }
    }
  }
}