@import '../../../styles/variables.scss';

.container {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 5%;
  @media only screen and (min-width: $sm-screen) {
    min-height: 90%;
  }
  @media only screen and (min-width: $md-screen) {
    padding-top: 0%;
  }
  @media only screen and (min-width: $lg-screen) {
    min-height: 80%;
  }
  .row {
    height: auto;
  }
  .code {
    margin-bottom: 20px;
    .input {
      max-width: 140px;
      // border: 1px solid $success;
    }
  }
  .anim {
    animation: anim 0.2s ;
    @include animation-mixin-x(anim , -100px , 0);
  }
  h2 {
    color: $primary;
    font-family: "Raleway-Bold";
    margin: 20px 0px
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    button {
      font-size: 12px;
      min-width: 120px;
      @media only screen and (min-width: $xxs-screen) {
        font-size: 14px;
        min-width: 140px;
      }
      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .message {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    img {
      max-width: calc(100% - 40px);
      margin: 20px;
    }
    @media only screen and (min-width: $sm-screen) {
      height: 100%;
    }
    h1 {
      font-family: "Raleway-Bold";
      font-size: 22px;
      margin-bottom: 20px;
      width: 98%;
      @media only screen and (min-width: $sm-screen) {
        max-width: 100%;
        font-size: 40px;
      }
    }
    button {
      margin: 30px 0px;
    }
    .back {
      button {
        margin: 0px;
        font-size: 14px;
        text-decoration: underline;
        font-family: "Raleway-Bold";
        color: $grey-light;
      }
    }
    .loading {
      min-height: 163px;
    }
  }
  .loader {
    height: 100%;
    width: 100%;
  }
}