$bg-color: #FFFFFF;
$white: #FFFFFF;
$dark: #15284B;
$primary: #4BC3C9;
$primary-dark: rgb(54, 148, 153);
$grey-extra-light: #F2F4FB;
$grey-light: #C6CBD8;

$success: #49aa56;
$danger: red;

$shadow: 0px 0px 13px -2px rgba(156,167,177,0.30);

$card-shadow: 0px 18px 32px -4px rgba(136,136,136,0.25);

$shadow-hover: 0px 0px 10px -2px rgba(80, 80, 80, 0.603);

$lg-screen: 1200px;
$md-screen: 1024px;
$sm-screen: 768px;
$xs-screen: 576px;
$xxs-screen: 340px;

$margin-md : 20px;
$margin-sm : 15px;
$margin-xs : 10px;

$small : 13px;
$regular : 16px;
$h2 : 18px;
$h1 : 25px;

$radius: 5px;

@font-face {
	font-family: "Raleway-Regular";
	src: url("../assets/fonts/Raleway-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Raleway-SemiBold";
	src: url("../assets/fonts/Raleway-SemiBold.ttf") format("truetype");
}

@font-face {
	font-family: "Raleway-Bold";
	src: url("../assets/fonts/Raleway-Bold.ttf") format("truetype");
}

@mixin transition {
  transition: all 0.2s ease-in-out;
}

@mixin slideInLogin {
	@include animation-mixin-y(slideIn, 30px, 0);
	animation-name: slideIn;
  animation-duration: 0.2s;
}

@mixin animation-mixin-x($name, $from, $to) {
  @keyframes #{$name} {
    0% {transform: translateX($from); opacity: 0;}
    100% {transform: translateX($to); opacity: 1;}
  }
}

@mixin animation-mixin-y($name, $from, $to) {
  @keyframes #{$name} {
    0% {transform: translateY($from); opacity: 0;}
    100% {transform: translateY($to); opacity: 1;}
  }
}

@mixin btn {
  text-align: center;
	cursor: pointer;
	font-family: "Raleway-Regular";
	transition: all 0.2s ease-in-out;
	background-color: transparent;
  outline: none;
  border: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0 30px $white inset !important;
}