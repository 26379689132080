@import '../../../../styles/variables.scss';

.input {
  width: 100%;
  input {
    @include transition;
    width: 100%;
    font-family: "Raleway-Regular";
    border-radius: 5px;
    padding: 10px 12px;
    font-size: 14px;
    margin-top: 5px;
    background-color: $white;
    transition: all 0.2s ease-in-out;
    border: 1px solid #C6CBD8;
    &.error {
      border: 1px solid $danger;
    }
    &:focus {
      outline: none;
      border: 1px solid $primary;
    }
  }
  .error-message {
    color: $danger;
    min-height: 20px;
    padding-top: 4px;
    font-size: 12px;
    margin: 0px;
  }
}