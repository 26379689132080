@import '../../../../styles/variables.scss';

.select {
  width: 100%;
  .error-message {
    margin: 0px;
    color: $danger;
    min-height: 20px;
    padding-top: 3px;
    font-size: 12px;
  }
}